export default {
    title: 'Zecar',
    'placeholder': 'ZéCar',
    // add other fields

    'content1': 'ZéCar is a toy designed by Guga Casari and Chico Bicalho for Kikkerland Design, to generate funds for the planting of trees in the Mil Folhas Project. The royalties from ZéCar sales were designated entirely to planting trees, and soon after, Kikkerland Design offered a generous matching fund, doubling the budget for the project.',
    'content2': 'The design process for ZéCar took place in Petrópolis (Brazil) in January 2001, and was an incredibly fluid and fun experience shared by two old friends with one serious intention; to reforest an 50 ha area that used to have a beautiful tropical forest they grew up with, a forest that was unnecessarily devastated for the duplication of a highway in the mid 1970s.',
    'content3': `Giga Casari, who in addition to being a brilliant product designer, was well versed in 3D modeling and rendering, so ZéCar was Kikkerland's first computer-designed critter.`,
    'content4': `In the 18 years since the ZéCar introduction, the royalty funds, and Kikkerland's generous contribution boosted the planting of trees from about 20 thousand to more than 320 thousand. Therefore, ZéCar is not only a zero-carbon footprint car but a car that produces massive amounts of oxygen.`,
    'content5': `Shop ZéCar`,
    'zecar1': `Zécar Foi O Primeiro Critter Desenhado Em Computador Pelo Guga Casari. Ver Essas Imagens "Super High Tech" No Ano 2000 Foi Muito Empolgante.`,
    'zecar2': `Logo Em Seguida, Marcos Taquechel Produziu Essas Lindas Renderizações A Partir Do Arquivo 3D Do Guga Casari, Baseadas No Nosso Conceito Inicial De Cores, Usando O Verde, Amarelo E Azul Da Bandeira Brasileira (Também As Cores Da Floresta). No Produto Final, Optamos Por Usar As Cores Apenas No Flywheel Deixando O Resto Do Zécar Em Metal.`,
    'zecar3': `Zécar É O Único Critter Que Não Usa Corda, E Tem Pouquíssimas Engrenagens. É O Automóvel Reduzido Ao Mínimo.`

};
