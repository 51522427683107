export default {
    title: 'Tools',
    'placeholder': 'Tools',
    // add other fields
  
    'content1': `Using the right tools, and keeping those tools in good condition makes all the difference in the world when working efficiently on a large-scale reforestation project. The proper tools optimize our efforts, and generate a better result on the field, with minimal waste of physical energy, making a day's hard work more effective and less tiring.`,
    'content2': `Accidents should be avoided at all costs, because when one is tired, any distraction can have serious consequences, especially when working with gasoline-powered cutting tools such as brushcutters, which are very important in a reforestation project. But a simple digger can cause serious injury when handled improperly and carelessly. The mower operator should always wear leather boots, shin guards, leather gloves, goggles and ear protection, and should never use the mower when tired, sick or hung over. The Mil Folhas project owns two Husqvarna 143R, which are excellent brushcutters.`,
    'content3': `It is important that diggers, hoes, shovels, augers, machetes, mower blades or scythes are kept clean and their blades always sharpened, so a good single cut flat file about 150 to 200 mm (6 to 8") long, should be kept with other small hand tools, and every rest period must be seized by sharpening the tools being used, always filing in one direction, feeling the file "bite" the metal. A sharp tool, be it a hoe or a digger, is more efficient and tires a lot less.`,
    'content4': `A good quality water pump can be very useful. The Project owns a Stihl P835 pump with 1" hose inlets with 10 m (33 ft.) of high pressure inlet water hoses, which can be fed from a stream or well, plus 10 m (33 ft.) of 1" high pressure hose for water outlet with a reduction for 3/4 " high pressure hoses and connectors to more hoses that can be either 3/4" or 1/2 ". We have enough hoses for watering more than 120 m (400 ft.) away from a water source, with very good pressure at that distance.`,
    'content5': `The fuel containers for the water pump and brushcutter should be the proper size for a day's work, as it is of no use carrying more fuel than you need. The  two-stroke oil mixture should be done the night before, in the exact amount recommended by the manufacturer, and with good quality oil. We use either Castrol Super TT, or Motul 710 2T. Careful and frequent maintenance of engines and other mechanical components is very important, as well as making sure air filters are always clean and intact. Above all, it is advisable not to push the two-stroke engines too hard, especially on hot days. A greaser, and good quality grease should be part of the brushcutter kit, and all pins be greased after a day of heavy machine use.`,
    'content6': `A medium-sized backpack is convenient for carrying lightweight tools for the brushcutter and water pump, a file and a basic Swiss army knife, leather gloves, water (lots of water), bananas, sandwiches, and some energy bars. The Swiss army knife should have, in addition to the blade, scissors, and tweezers, which can be useful for removing splinters. A clean cloth and a small bottle of alcohol gel should also be in the backpack along with some Band-Aid, for obvious reasons.`,
    'content7': `Clothing is each person's choice, but in our project, the footwear for the summer are white rubber boots, because in the hot sun they heat up our feet a lot less than black boots. A very large straw hat protects the head, shoulders and neck, and red cotton t-shirts help for easy viewing in the midst of plants so it is easier to locate each other, and long comfortable cargo pants with many pockets work very well.`,
    'content8': `Other important tools are the ones used for combatting fires. Contact the IEF (Instituto Estadual de Florestas) in your area to learn how to train a forest fire brigade, but essentially the two important tools are a fire beater made from a rubber plaque attached to a long handle, and a rakehoe which is a rake with a hoe blade on the opposite side (2 in 1) used to remove dry leaves and twigs from the path of the fire while another person beats down the flames. Research.`,
    'content9': `The ant bait box is another important tool, especially in the Southern hemisphere spring months (Sept. Oct. Nov.) during the first plantings, because a single colony of leaf-cutting ants can ruin the planting of 3,000 seedlings in just one week. We pity the ants, but when the trees grow, there will be plenty of leaves available for them, however in the beginning, when the saplings are very small, ants can do a lot of damage. MEC-Plant, in addition to many other products, manufactures an excellent bait holder, which just needs to be placed right on the ants' trail with the bait inside and they do the rest.`,
    'content10': `Over time, each person chooses the tools that work best for themselves, and better adapts to their personal working methods.`,
    'hats': 'White Rubber Boots (For The Summer), Wide Brimmed Straw Hats, And Leather Gloves Are The Basics For A Day Working In The Field. Reminding That The Brush Cutter Operator Should Also Wear Goggles, Shin Guards, Leather Boots, And Ear Plugs Or Muffs.',
    'saws': 'During The Maintenance Period, After The Planting Is Done, Cutting Tools Can Be Important In The Removal Of Parasites From The Saplings. Keeping In Mind That There Is No Need To Prune Any Trees, Unless There Are Parasites, Such As The Erva-De-Passarinho. From The Top, A Long Reach Stem Tree Pruner For High To Reach Points, A Long Reach Saw, A Hand Saw, Pruning Shears, And A Machete.'
    

  };
  