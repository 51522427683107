export default {
  title: 'Zecar',
  'placeholder': 'ZéCar',
  // add other fields

  'content1': 'ZéCar is a toy designed by Guga Casari and Chico Bicalho for Kikkerland Design, to generate funds for the planting of trees in the Mil Folhas Project. The royalties from ZéCar sales were designated entirely to planting trees, and soon after, Kikkerland Design offered a generous matching fund, doubling the budget for the project.',
  'content2': 'The design process for ZéCar took place in Petrópolis (Brazil) in January 2001, and was an incredibly fluid and fun experience shared by two old friends with one serious intention; to reforest an 50 ha area that used to have a beautiful tropical forest they grew up with, a forest that was unnecessarily devastated for the duplication of a highway in the mid 1970s.',
  'content3': `Giga Casari, who in addition to being a brilliant product designer, was well versed in 3D modeling and rendering, so ZéCar was Kikkerland's first computer-designed critter.`,
  'content4': `In the 18 years since the ZéCar introduction, the royalty funds, and Kikkerland's generous contribution boosted the planting of trees from about 20 thousand to more than 320 thousand. Therefore, ZéCar is not only a zero-carbon footprint car but a car that produces massive amounts of oxygen.`,
  'content5': `Shop ZéCar`,
  'zecar1': `Zécar Was The First Critter Designed In A Computer By Guga Casari. To See These "Super High Tech" Images In The Year 2000 Was Very Exciting.`,
  'zecar2': `Soon After, Marcos Taquechel Produced These Beautiful Renderings Based On Our Initial Color Scheme Using The Green, Yellow, And Blue Of The Brazilian Flag (Also The Forest Colors). But In The Final Product We Opted For These Colors Only On The Flywheels, Leaving The Rest Of Zécar In Metal`,
  'zecar3': `Zécar Is The Only Critter That Does Not Have A Spring, And It Has Very Few Gears. It Is The Automobile Reduced To A Minimum.`

};
