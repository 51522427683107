export default {
    title: 'People',
    'placeholder': 'People',
    'content1': `Many people have collaborated with the Mil Folhas Project for the past 22 years. Some names have been forgotten, unfortunately, but their dedication is present in the more than 320,000 trees that have been planted all these years. Below is a list of people, with their last names in alphabetical order, and a few collaborators whose actual names we never learned, and only their nicknames remained. The important thing is, their contribution will always be remembered, and very much valued:`,
    'names': '',

    'kids': 'Students From The E.E. João Kopke During One Of Many Walks From The School To The Planting Area, In Front Of A Land Slide Due To A Deforested Slope.'

   
 
  };
  