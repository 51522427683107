export default {
    title: 'The Atlantic Forest',
    'placeholder': 'A Mata Atlântica',

    'content1': `A Mata Atlântica é extrememente rica e complexa com diversos tipos de bioma, entre eles a restinga nas vastas áreas de costeiras, e as florestas de altitude, se extendendo ao longo da costa brasileira do Atlântico no estado do Rio Grande do Norte até o sul do Rio Grande do Sul, entrando no continente para o Paraguai, até a provincia de Misiones no nordeste da Argentina e na costa do Uruguai. Ela adentra o continente brasileiro até Minas Gerais, e se espalha por ilhas costeiras incluindo Fernando de Noronha. Essas florestas, se extendem de 500 a 600 quilometros para dentro do continente, e alcançam altitudes de mais de 2.300 metros acima do nível do mar. Altitude determina pelo menos três tipos de vegetação na Mata Atlântica: Ao longo da costa, chamada de restinga, As florestas de montanhas chamadas de encosta, e as de altitude, denominadas campo rupestre.`,

    'content2': `A Mata Atlântica, considerada hoje um hotspot (núcleo de grande importância)  de biodiversidade, é mais conhecido por sua enorme variedade de espécies de plantas e animais. Isolada de outros blocos florestais importantes na América do Sul, A Mata Atlântica possui uma diversidade única e variedade enorme de vegetação e tipos de floresta. A Mata Atlántica também comporta uma rede rede hidrográfica, e uma quantidade imensa de água doce.`,

    'content3': `A Mata Atlântica já ocupou uma área de mais de 1.3 milhão de quilometros quadrados, ao longo da América do Sul tropical. Hoje, contudo, a floresta foi reduzida a pouco mais de 10 por cento do seu tamanho original. Ameaças diretas a sua biodiversidade iclui a extração de madeira, caça, tráfico de animais, o desenvolvimento urbano residencial e industrial, e também o desmatamento provocado pela agricultura e agro pecuária.`,

    'content4': `A Mata Atlântica, possui a maior variedade de espécies de árvores por quilometro quadrado no mundo, mais de 700 espécies e sub spécies ao todo, e abriga mais de 20.000 espécies de plantas, 40 por cento das quais endêmicas. Mais de 42.000 espécies de vertebrados dos quais 6.300 répteis já foram catalogados, 9.000 espécies de pássaros, e mais de 4.000  espécies de mamíferos já foram identificados. O número de espécies de invertebrados ultrapassa os 990.000.`,

    'content5': `Começando com as plantações de cana de açúcar no século 18, e, logo em seguida com o plantio de café, essa floresta enorme tem perdido habitat durante centenas de anos. Hoje com a expansão de áreas urbanas em toda a sua extensão, a Mata Atlântica sofre uma enorme presssão com a questão da urbanização.`,

    'caption1': `A Maior Cachoeira Do Rio São Francisco, A Casca D'anta, No Parque Nacional Da Serra Da Canastra, Em Minas Gerais`,
    'caption2': `Vista Do Morro Do Facão (Alt. 1.214 M), Com A Mata Atlântica Subindo Por Suas Íngremes Encostas.`,
    'caption3': `Cachoeira Em Aiuruoca, Minas Gerais.`,



  
    'Go to page 2': 'Go to page 2',
};
