module.exports = [
  {
    locale: 'pt',
    label: 'Português'
  },
  {
    locale: 'en',
    label: 'English',
    default: true
  },
];
