export default {
    title: 'Home',
    'placeholder': 'portugeuse home page content',
    'Welcome to your new Gatsby site': 'Welcome to your new Gatsby site.',
    'Now go build something great': 'Now go build something great.',
    'or learn more': '...or learn more.',
    'Go to page 2': 'Go to page 2',
    'contactlink': 'Contato',
    'projlink': 'O Projeto',
    'reforestlink': 'Reflorestamento',
    'atlforestlink': 'A Mata Atlântica',
    'zecarlink': 'ZéCar',
    'peoplelink': 'Gente',
    'toolslink': 'Ferramentas',
    'manuallink': 'Manual',
    'treeslink': 'Arvores Plantadas',
    'saplingslink': 'Mudas'


  };
  